<template>
  <div>
    <div
      @click="useScan"
    >
      <slot />
    </div>
    <input
      id="scan-input"
      v-model="scanValue"
      class="hidden-input"
      autocomplete="off"
      @input="fetchResults"
    >
    <b-modal
      id="bs-warning-modal"
      ref="bs-warning-modal"
      centered
      hide-header
      hide-footer
      modal-class="bs-warning-modal"
    >
      <div class="text-center py-1">
        <h4 class="font-weight-bolder">
          {{ 'Asset 2378623 incorrect' }}
        </h4>
        <p class="pt-1">
          {{ 'You scanned:' }}
        </p>
        <p class="pt-1">
          {{ 'SK14793521 — Sony PXW FS7 XDCAM 4K Super 35 Camera' }}
        </p>
        <p class="pt-1">
          {{ 'Please make sure you’re scanning the proper item.' }}
        </p>
        <b-button
          variant="success"
          class="font-medium-1 font-weight-bold px-3"
          @click="hideModal"
        >
          <span>{{ 'OK' }}</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>

import { debounce } from '@core/utils/utils'
import includes from 'lodash/includes'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'Scan',
  components: { BButton },
  props: {
    assetItemSerialNumbers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      scanValue: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.focusInput()
    })
  },
  methods: {
    focusInput() {
      const inputElement = document.getElementById('scan-input')
      if (inputElement) {
        inputElement.focus()
      }
    },
    refocusInput() {
      this.$nextTick(() => {
        this.focusInput()
      })
    },
    fetchResults(value) {
      this.scan(this.scanValue, this)
      this.$emit('scanning', value)
    },
    scan: debounce((value, ctx) => {
      ctx.getValue(value)
    }, 150),
    getValue(value) {
      const hasItem = includes(this.assetItemSerialNumbers, value)

      if (hasItem) {
        this.$emit('getValue', value)
      } else {
        this.showModal()
      }

      this.scanValue = ''
    },
    useScan() {
      this.$emit('useScan')
      this.focusInput()
    },
    showModal() {
      this.$bvModal.show('bs-warning-modal')
    },
    hideModal() {
      this.$bvModal.hide('bs-warning-modal')
    },
  },
}
</script>
<style scoped lang="scss">
.hidden-input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  width: 0;
  height: 0;
}
#bs-warning-modal {
  .modal-dialog {
    max-width: 500px !important;
  }
}
</style>
