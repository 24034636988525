<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <span class="font-weight-bolder font-medium-5 d-block pt-2">
        {{ $t('Order Information') }}
      </span>
      <b-row class="mt-1 pb-1">
        <b-col md="6">
          <b-row>
            <b-col mb="8">
              <b-col
                mb="8"
                class="d-flex align-items-center justify-content-between"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Supplier') }}
                </span>
                <span class="w-50">
                  {{ getValueFromGivenObjectByKey(getServiceOrder,'customer.cust_name_dba') }}
                </span>
              </b-col>
              <b-col
                mb="8"
                class="d-flex align-items-center justify-content-between mt-1"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Placed by') }}
                </span>
                <span class="w-50">
                  {{ getValueFromGivenObjectByKey(getServiceOrder,'customer.cust_name_dba') }}
                </span>
              </b-col>
            </b-col>
            <b-col mb="4" />
          </b-row>
          <b-col
            v-for="field of ['warehouseNotes']"
            :key="field"
            md="12"
            class="mt-1"
          >
            <component
              :is="ServiceOrderPickFields[field].type"
              style="width: 100%"
              v-bind="getProps(field, ServiceOrderPickFields)"
            />
          </b-col>
        </b-col>
        <b-col md="6">
          <b-col
            v-for="field of [ 'orderNotes']"
            :key="field"
            md="12"
            class="mt-1"
          >
            <component
              :is="ServiceOrderPickFields[field].type"
              v-model="getServiceOrder.internal_notes"
              style="width: 100%"
              is-editable
              v-bind="getProps(field, ServiceOrderPickFields)"
            />
          </b-col>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { formatDate, getProps, getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from '@/views/main/warehouse/view/pick/service-order/serviceOrderConfig'

export default {
  name: 'ServiceOrderInformation',
  components: { BCol, BRow },
  computed: {
    getServiceOrder() {
      return this.$store.state[this.MODULE_NAME].getServiceInformation
    },
  },
  methods: {
    getValueFromGivenObjectByKey,
    formatDate,
  },
  setup() {
    const { ServiceOrderPickFields, MODULE_NAME } = config()
    return {
      ServiceOrderPickFields,
      MODULE_NAME,
      getProps,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
