<template>
  <div>
    <skeleton v-if="loading" />
    <div v-else>
      <quote-status-bar
        :item="quoteStatusItem"
        :quote-status-bar="quoteStatusBar"
        class="ml-1"
      />
      <b-row class="mt-1">
        <b-col cols="6">
          <service-order-dispatch />
        </b-col>
        <b-col cols="6">
          <service-order-return />
        </b-col>
      </b-row>
      <service-order-information class="mt-1" />
      <service-order-items class="mt-1" />
      <service-order-form-bottom />
    </div>

  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import ServiceOrderDispatch from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderDispatch.vue'
import ServiceOrderReturn from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderReturn.vue'
import ServiceOrderInformation from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderInformation.vue'
import ServiceOrderFormBottom from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderFormBottom.vue'
import ServiceOrderItems from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderItems.vue'
import config from '@/views/main/warehouse/view/pick/service-order/serviceOrderConfig'
import { BCol, BRow } from 'bootstrap-vue'
import skeleton from '@/views/main/warehouse/view/pick/service-order/components/Skeleton.vue'

export default {
  name: 'ServiceOrderForm',
  components: {
    skeleton,
    BCol,
    BRow,
    ServiceOrderDispatch,
    QuoteStatusBar,
    ServiceOrderReturn,
    ServiceOrderInformation,
    ServiceOrderFormBottom,
    ServiceOrderItems,
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
    }
  },
  computed: {
    getServiceInformation() {
      return this.$store.state[this.MODULE_NAME].getServiceInformation
    },
    quoteStatusItem() {
      return this.getServiceInformation ? {
        state: this.getServiceInformation.fulfillment_state,
        status: this.getServiceInformation.fulfillment_status,
      } : {}
    },
  },
  created() {
    this.$store.dispatch(`${this.MODULE_NAME}/getServiceOrder`, { id: this.id }).then(res => {
      const { data } = res.data
      this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_INFORMATION`, data)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    }).finally(() => {
      this.loading = false
    })
  },
  setup() {
    const { transferFields, MODULE_NAME, quoteStatusBar } = config()
    return {
      transferFields,
      MODULE_NAME,
      quoteStatusBar,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
