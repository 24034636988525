<template>
  <scan
    :asset-item-serial-numbers="assetItemSerialNumbers"
    @getValue="handleCheckBySerialNumber"
  >
    <div class="bg-white px-1 rounded-lg">
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Order Items') }}
      </h2>
      <span>
        <!--        <feather-icon-->
        <!--          icon="LWarningIconBlue"-->
        <!--          class="featherIcon"-->
        <!--          size="30"-->
        <!--        />-->

        <!--        {{ isIndeterminate ? 'Scan the assets to be picked' : $t('To start the pick process, press pick or uncheck the items not to be picked.') }}</span>-->
        <l-table-list-collector
          ref="lTableRef"
          :table-columns="tableColumns"
          :module-name="MODULE_NAME"
          :fetched-data="assetsItem"
          :is-searchable.camel="false"
          :is-fullfillment="true"
        >
          <template #head(action)>
            <div
              class="d-flex p-0"
            >
              <b-form-checkbox
                :indeterminate="isIndeterminate && !pickedAllItems"
                :checked="pickedAllItems"
                @change="handleCheckAllItems"
              />
            </div>
          </template>
          <template #cell(action)="{ data: { item }}">
            <b-form-checkbox
              :checked="item.picked"
              @change="handleCheckItem($event, item.id)"
            />
          </template>
          <template #cell(state)="{ data: { item }}">
            <div
              class="d-flex pl-1"
            >
              <state-of-asset
                :data="item"
                :with-label="false"
              />
            </div>
          </template>
          <template #cell(sku)="{ data: { item }}">
            {{ getValueFromGivenObjectByKey(item, 'serial_number', '—') }}
          </template>
          <template #cell(name)="{ data: { item }}">
            {{ getValueFromGivenObjectByKey(item, 'product.name', '—') }}
          </template>
          <template #cell(asset_id)="{ data: { item }}">
            {{ getValueFromGivenObjectByKey(item, 'id', '—') }}
          </template>
          <template #cell(picked_status)>
            <div class="text-center">
              <feather-icon
                v-if="getServiceInformation.inventory_item_damaged.pick_at"
                icon="LApproved"
                size="20"
              />
            </div>
          </template>
        </l-table-list-collector>
      </span></div>
  </scan>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/service-order/serviceOrderConfig'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BFormCheckbox } from 'bootstrap-vue'
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import { assetStates } from '@/enum/asset-statuses'
import Scan from '@/views/components/Scan/Scan.vue'
import { map, find } from 'lodash'

export default {
  name: 'ServiceOrderItems',
  components: {
    Scan, StateOfAsset, BFormCheckbox, LTableListCollector,
  },
  computed: {
    assetStates() {
      return assetStates
    },
    isIndeterminate() {
      return this.assetsItem.some(item => item.picked)
    },
    pickedAllItems() {
      return this.assetsItem.every(item => item.picked)
    },
    assetsItem() {
      return this.$store.state[this.MODULE_NAME].assetsItem
    },
    assetItemSerialNumbers() {
      return map(this.assetsItem, 'serial_number')
    },
    getServiceInformation() {
      return this.$store.state[this.MODULE_NAME].getServiceInformation
    },
  },
  methods: {
    handleCheckAllItems(checked) {
      const checkedItems = this.assetsItem.map(item => ({ ...item, picked: checked }))
      this.$store.commit(`${this.MODULE_NAME}/SET_ASSETS_ITEM`, checkedItems)
    },
    handleCheckItem(checked, id) {
      const checkedItems = this.assetsItem.map(item => (item.id === id ? { ...item, picked: checked } : item))
      this.$store.commit(`${this.MODULE_NAME}/SET_ASSETS_ITEM`, checkedItems)
    },
    handleCheckBySerialNumber(serialNumber) {
      const checkedItems = find(this.assetsItem, { serial_number: serialNumber })
      if (!checkedItems) return
      this.handleCheckItem(!checkedItems.picked, checkedItems.id)
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      MODULE_NAME,
      tableColumns,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
