export default {
  SET(state, data) {
    state.serviceOrder = { ...data }
  },
  SET_SERVICE_INFORMATION(state, data) {
    state.getServiceInformation = data

    const assets = data?.inventory_item_damaged?.inventory_item
    state.assetsItem = Object.keys(assets || {}).length ? [assets].map(item => ({ ...item, _rowVariant: data?.inventory_item_damaged?.pick_at && 'success' })) : []
  },
  SET_ASSETS_ITEM(state, data) {
    state.assetsItem = data
  },
  SET_RE_RENDER_COMPONENT_KEY(state) {
    state.reRenderComponentKey += 1
  },
}
