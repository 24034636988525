<template :key="reRenderComponentKey">
  <div :key="reRenderComponentKey">
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t('Fulfillment for Order SM-') }} {{ id }}
      </h3>
    </portal>
    <service-order-form />
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/service-order/serviceOrderConfig'
import store from '@/store'
import serviceModule from '@/store/main/warehouse/pick/service-order'
import { onUnmounted } from '@vue/composition-api'
import ServiceOrderForm from './components/ServiceOrderForm.vue'

export default {
  name: 'RentalSales',
  components: { ServiceOrderForm },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  computed: {
    reRenderComponentKey() {
      return this.$store.state[this.MODULE_NAME].reRenderComponentKey
    },
  },
  methods: {},
  setup() {
    const { MODULE_NAME } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, serviceModule)

    // onUnmounted(() => {
    //   if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    // })

    return {
      MODULE_NAME,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
